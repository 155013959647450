* {
  font-family: Montserrat;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.sandbox-main-container input {
  border: 1px solid #cecece;
  padding: 0 10px;
  font-size: 16px;
}
.phr-logo {
  width: 186px;
  margin-right: 10px;
  margin-top: 70px;
  margin-bottom: 60px;
}
.mail-pera-div a {
  color: #491094;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.59px;
  font-family: "Montserrat";
  text-decoration: underline;
  word-wrap: break-word;
}

.mail-logo-div {
  display: flex;
  justify-content: center;
  margin: 0px auto 0px;
}
.api-link-menu,
.api-link-menu:hover {
  text-align: center;
  font: normal normal 600 14px/18px Montserrat;
  letter-spacing: 0.14px;
  color: #10204b;
  width: 100%;
  display: block;
  text-decoration: none;
  margin-top: 60px;
}

@media (min-width: 768px) {
  .mail-logo-div {
    display: flex;
    justify-content: center;
    margin: 0px auto 0px;
  }
}

@media (min-width: 300px) {
  .sandbox-container {
    width: 90%;
    margin: auto;
  }
}

@media (min-width: 576px) {
  .sandbox-container {
    width: 90%;
    margin: auto;
  }
}

@media (min-width: 768px) {
  .sandbox-container {
    width: 75%;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .sandbox-container {
    width: 720px;
    margin: auto;
  }
}

.ft-logo img {
  width: 210px;
}

.ft-logo {
  display: flex;
  flex-direction: column;
  margin: 75px 0px 0px;
  padding-bottom: 60px;
  align-items: center;
}

.sandbox-main-container {
  position: relative;
}
@media (min-width: 300px) {
  .sandbox-main-container {
    width: 90%;
    margin: 20px auto;
    background-color: #ffffff;
  }
}

@media (min-width: 768px) {
  .sandbox-main-container {
    width: 90%;
    margin: 90px auto;
    background-color: #ffffff;
  }
}

@media (min-width: 992px) {
  .sandbox-main-container {
    width: 85%;
    margin: 90px auto;
    background-color: #ffffff;
  }
}

@media (min-width: 1200px) {
  .sandbox-main-container {
    width: 1088px;
    margin: 90px auto;
    background-color: #ffffff;
  }
}

.sandbox-main-container .back img {
  margin: 20px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
}

body {
  background-color: #f0f0f0;
}

.sandbox-main-container p {
  text-align: left;
  font: normal normal normal 14px/24px Montserrat;
  letter-spacing: 0px;
  color: #0d181d;
  line-height: normal;
}
.accordion-item:first-of-type .accordion-btn,
.accordion-btn {
  color: #ffffff !important;
  background-color: #285fa9 !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  text-align: left;
  font: normal normal 600 18px/22px Montserrat;
  letter-spacing: 0px;
}
.accordion-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
.accordion-btn[aria-expanded="true"]::after {
  background-image: url("../assets/images/remove_icon.svg") !important;
  transform: rotate(-180deg);
}
.accordion-btn[aria-expanded="false"]::after {
  background-image: url("../assets/images/add_icone.svg") !important;
  transform: rotate(-180deg);
}
.accordion-btn::after {
  flex-shrink: 0;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  transition: transform 0.2s ease-in-out;
  content: "";
  height: 30px;
  width: 30px;
  background-size: cover;
}

.accordion-body {
  padding: 45px 1.25rem;
}

.accordion-body h1 {
  text-align: left;
  font: normal normal 600 14px/22px Montserrat;
  letter-spacing: 0.14px;
  margin-bottom: 4px;
}
.api-card-block {
  margin-bottom: 20px;
}

/* api input block */

.api-input-block h1 {
  text-align: left;
  font: normal normal 600 16px/22px Montserrat;
  letter-spacing: 0.16px;
  color: #0d181d;
  margin: 40px 0;
}
.api-input-group {
  display: block;
  margin-bottom: 15px;
}
.api-input-group:last-child {
  display: block;
  margin-bottom: 0;
}
.api-input-group input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.api-input-group label {
  position: relative;
  cursor: pointer;
  background-color: #d1c1da;
  max-width: 366px;
  width: 100%;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font: normal normal 600 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #0d181d;
}

.api-input-group input:checked + label {
  background-color: #153a66;
  color: #fcfeff;
}

/* api input block end */

.api-data-heading {
  margin-top: 55px;
}
.api-data-heading h1 {
  text-align: left;
  font: normal normal bold 22px/27px Montserrat;
  letter-spacing: 0px;
  color: #11214b;
  margin-bottom: 15px;
}

.api-data-group,
.api-headers {
  margin-top: 35px;
}
.api-data-group h1,
.api-headers h1 {
  text-align: left;
  font: normal normal 600 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #0d181d;
  margin-bottom: 15px;
}
.api-base-output {
  display: flex;
  align-items: center;
  min-height: 47px;
  padding: 16px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #cecece;
}

.api-base-output span {
  text-align: left;
  font: normal normal normal 14px/16px Courier New;
  letter-spacing: 0px;
  color: #b20e0e;
  word-break: break-all;
}
.api-auth-key-block {
  display: grid;
  grid-template-columns: 40fr 60fr;
  width: 100%;
  margin: 20px 30px;
  border-bottom: 1px solid #e7e7e7;
}

.api-auth-key-block h2,
.api-endpoint-block h2 {
  text-align: left;
  font: normal normal 600 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #0d181d;
}
.api-auth-key-block h2 {
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  margin: 0;
  white-space: nowrap;
}
.api-auth-key-block p,
.api-auth-key-block input {
  margin: 25px 0 25px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  display: grid;
  min-height: 30px;
  align-items: center;
  width: 100%;
}

.api-auth-key-block a {
  text-align: left;
  // text-decoration: underline;
  font: normal normal 600 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #000;
}
.api-endpoint-block {
  padding: 40px;
}
.api-endpoint-data div {
  display: flex;
  gap: 10px;
}
.api-endpoint-data span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 40px;
  border: 1px solid #707070;
  text-align: left;
  font: normal normal 600 14px/18px Montserrat;
  letter-spacing: 0px;
}
.api-endpoint-data span:first-child {
  background: #275db2;
  color: #ffffff;
}
.api-endpoint-data span:last-child {
  border: 1px solid #707070;
}

.parameter-info {
  display: grid;
  gap: 15px;
}

.api-parameters-block h2 {
  margin: 40px 0;
}
.table > :not(:first-child) {
  border-top: 1px solid #cecece;
}

.parameter-info tr {
  vertical-align: baseline;
}
.parameter-info th {
  text-align: left;
  font: normal normal 600 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #0d181d;
  height: 45px;
}

.parameter-info .table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.parameter-info .table thead,
.parameter-info .table tbody {
  max-width: 800px;
  /* display: block; */
}

.table > :not(caption) > * > * {
  border-bottom-width: 0;
}

.api-responses h2 {
  margin-bottom: 15px;
}

.parameter-info table th:first-child,
.parameter-info table td:first-child {
  padding-left: 0;
}

.parameter-info table td {
  text-align: left;
  font: normal normal normal 14px/16px Courier New;
  letter-spacing: 0px;
  color: #b20e0e;
}
.parameter-info table td h2 {
  margin: 30px 0;
}

.api-headers textarea {
  font-size: 12px;
  width: 100%;
  min-height: 280px;
  padding: 10px;
  border: 1px solid #cecece;
  border-radius: 4px;
  outline: none;
  background: hsla(0, 0%, 100%, 0.8);
  font-family: monospace;
  font-weight: 600;
  color: #3b4151;
}

.api-headers textarea:disabled{
  background: rgb(206 206 206 / 54%);
}

code[class*="language-"],
pre[class*="language-"] {
  white-space: pre-line !important;
}
code {
  display: block;
  width: auto !important;
  padding: 40px 50px 40px 45px !important;
  font-size: 0.8em;
  border-radius: 10px !important;
  box-shadow: 0 16px 40px -5px rgba(0, 0, 0, 0.5);
}
.api-btn {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  min-height: 40px;
  color: #fff !important;
  background-color: #0060af !important;
  font-size: 14px;
  padding: 0 8px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #0060af !important;
  min-width: 150px;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.api-auth-btn-block {
  display: grid;
  gap: 15px;
  width: 100%;
  grid-template-columns: repeat(auto-fit,minmax(155px,max-content));
  justify-content: flex-end;
  margin-top: 20px;
}
.block-right {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 568px) {
  .api-auth-key-block {
    margin: 20px 20px;
    gap: 10px;
  }
  .api-endpoint-block {
    padding: 20px;
  }
}
.prettier-code {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 19px;
  max-height: 400px;
  overflow-y: scroll;
  min-height: 400px;
  background-color: #000;
  color: #FFF !important;
}

.prettier-code  *{
  color:#ffffff !important;
  font-weight: 500;
}

.custom-table table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.custom-table table td,
.custom-table table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.custom-table table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.custom-table table tr:hover {
  background-color: #ddd;
}

.custom-table table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #285fa9;
  color: white;
}

.api-parameter-block{
  display: flex;
  justify-content: space-between;
  align-items: center;
}