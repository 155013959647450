$white: #ffffff;
$black: #000000;
$violet: #5b2f92;
$green: #B9B9B9;
$primary-blue: #342775;
$secondary-blue: #2B388B;
$dark-blue: #004e8b;
$dark-text: #212035;
$lime-yellow: #f0f3d3;
$secondary-bg: #f0f0f0;
$input-bg: #e8f0fe;
