@import "../../styles/variables.scss";

.form-group {
  margin-bottom: 50px;
  max-width: 600px;
  .form-control:focus {
    box-shadow: none !important;
  }
  label {
    color: $dark-text;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 0px;
    letter-spacing: 1.4px;
  }
  input,
  textarea {
    border: 1px solid #E0E0E0;
    height: 50px;
    background: #fff;
    padding: 10px;
    margin-bottom: 14px;
    margin-top: 10px;
  }
  textarea {
    height: 119px;
  }
  input::placeholder,
  textarea::placeholder {
    color: #6c757d;
    font-size: 16px;
    font-style: italic;
  }
  input:focus,
  textarea:focus {
    background: $input-bg;
  }
  .input-link {
    color: $secondary-blue;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  .form-control {
    padding-right: 50px;
  }
}

.form-login {
  position: relative;
  margin-bottom: 18px;
  text-align: left;
  label {
    font-size: 16px;
    font-weight: 500;
    color: #342775;
    letter-spacing: 0.8px;
    margin-bottom: 0px;
  }
  .input-icon {
    position: absolute;
    right: 8px;
    bottom: 8px;
  }
}

.form-common {
  position: relative;
  margin-bottom: 18px;
  label {
    font-size: 16px;
    font-weight: 500;
    color: #342775;
    letter-spacing: 0.8px;
    margin-bottom: 0px;
  }
}