@import "../../styles/variables.scss";

.close {
  position: absolute;
  top: 30px;
  right: 30px;
}

.header {
  color: $dark-text;
  font-size: 28px;
}

.content {
  font-size: 21px;
}

.overlay-base {
  text-align: center;
  padding: 1.5rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: overlay;
}

.overlay-after {
  // background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(14px);
  opacity: 1;
  z-index: 1000;
}

.overlay-before {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.content-base {
  position: relative;
  margin: auto;
  border: 0;
  outline: 0;
  align-items: center;
  width: 0%;
  background-color: transparent;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 4px;
}

.content-after {
  max-width: 736px;
  width: 100%;
  background-color: $white;
}

.content-before {
  width: 0%;
  height: 0%;
  background-color: transparent;
}

.popup-btn-block {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .popup-btn-block {
    display: grid;
    gap: 20px;
    justify-content: center;
    justify-items: center;
    button {
      margin: 0 !important;
      padding: 10px 48px;
    }
  }
}

.current-tag-area {
  padding: 50px 50px 20px;
  text-align: left;
  .search-container {
    display: flex;
    position: relative;
    width: 100%;
    .search-transition {
      transition: all 0.5s ease-in;
    }

    .search-input {
      width: 100%;
      height: 50px;
      border: 1px solid $green;
      outline: none;
      padding-left: 20px;
      padding-right: 50px;
      line-height: 52px;
    }
    .search-btn {
      position: absolute;
      right: 10%;
    }
  }
  .tag-count {
    text-align: center;
    margin: 35px 0;
    p {
      margin: 0;
      color: #000000;
      font-size: 20px;
      font-weight: 500;
      span {
        font-weight: 900;
      }
    }
  }
  .selected-tag {
    .tags {
      margin-right: 10px !important;
      padding: 0 20px;
      span {
        font-size: 14px;
      }
    }
  }
  .table-area {
    table {
      width: 100%;

      tbody {
        width: 100%;
        tr:nth-child(odd) td {
          background: #F7F7F7;
        }
        tr:nth-child(even) td {
          background: $white;
        }
        td {
          width: 100%;
          padding: 20px 25px;
        }
      }
    }
  }
  .tag-pagination {
    .pagination {
      margin-top: 20px;
    }
  }
}

.current-tag-area .search-input::placeholder {
  color: #6c757d;
  font-weight: 400;
  font-size: 16px;
}

.tag-list-block {
  max-height: 250px;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(145px,max-content));
  gap: 16px;
}

.fw-bold{
  padding-left: 0px;
}
.dropdown-item:hover{
 color: #000000 !important;
  background-color: $input-bg !important;
}
.dropdown-item{
  border-bottom: 2px solid $input-bg !important;
}