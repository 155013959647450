/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/montserrat/montserrat-v21-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../assets/fonts/montserrat/montserrat-v21-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/montserrat/montserrat-v21-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/montserrat/montserrat-v21-latin-regular.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/montserrat/montserrat-v21-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../assets/fonts/montserrat/montserrat-v21-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/montserrat/montserrat-v21-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-500.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/montserrat/montserrat-v21-latin-500.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/montserrat/montserrat-v21-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../assets/fonts/montserrat/montserrat-v21-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/montserrat/montserrat-v21-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-600.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/montserrat/montserrat-v21-latin-600.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* montserrat-italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/montserrat/montserrat-v21-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../assets/fonts/montserrat/montserrat-v21-latin-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/montserrat/montserrat-v21-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/montserrat/montserrat-v21-latin-500italic.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-600italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/montserrat/montserrat-v21-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../assets/fonts/montserrat/montserrat-v21-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/montserrat/montserrat-v21-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-600italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/montserrat/montserrat-v21-latin-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/montserrat/montserrat-v21-latin-600italic.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
