.footer-menu-block {
    position: fixed;
    bottom: 0;
    z-index: 50;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #FFFFFF;
    border-top: 1px solid #003a69;
    min-height: 52px;
}

@media (min-width:569px) {
    .footer-menu-block {
        display: none;
    }
}
.footer-bento-menu-block {
    display: block;
    cursor: pointer;
}

.footer-bento-menu-section {
    border: 1px solid #bfbfbf;
    bottom: 52px;
    min-height: 250px;
}

.footer-bento-menu-section {
    position: absolute;
    left: 0;
    z-index: 30;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2,minmax(0,1fr));
    overflow: hidden;
    background-color: #FFF;
}

.footer-bento-menu-section a, .footer-bento-menu-section a:hover{
    color: #072f33;
    min-width: 117px;
    min-height: 117px;
    border: 1px solid #f3f1f1;
    font-size: 16px;
    line-height: 24px;
}

.bento-list {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-menu-block svg {
    fill: #390a65;
}

 svg {
    display: block;
    vertical-align: middle;
}

.bento-menu-section a span, .footer-bento-menu-section a span {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.75rem;
    text-align: center;
    word-break: break-word;
}

.no-underline{
    text-decoration: none;
}