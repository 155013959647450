@import "../../styles/variables.scss";

.custom-toast {
  width: 373px;
  height: 127px;
  /* UI Properties */
  background: #ffffff;
  box-shadow: 0px 9px 20px #0000000f;
  border-radius: 10px;
  .title {
    background: #A8057B;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 45px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding: 0 20px;
  }
  .content {
    font-size: 16px;
    padding: 20px;
  }
}
