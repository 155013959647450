.profile-section{
    background-color: #FFFFFF;
}
.profile-block{
max-width: 600px;
width: 90%;
margin: auto;
padding: 70px 0;
}
.profile-block form{
    margin-top: 35px;
}
.form-group .psw-link-block{
display: flex;
justify-content: flex-end;
}
.psw-link-block span{
    color: #035faf;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
}
