@import "../../styles/variables.scss";

header {
  color: $white;
  .profile {
    // height: 132px;
    background: $secondary-blue;
    .amity-logo {
      height: 130px;
    }
    .options {
      .text-box {
        background: $white;
        color: $black;
        height: 40px;
        width: 40px;
        .small {
          font-size: 12px;
        }
        .big {
          font-size: 22px;
        }
      }
    }
    .my-account-mobile a {
      color: $white;
      font-size: 0.8rem;
      font-weight: 600;
    }
    .my-account-web a {
      color: $white;
      font-weight: 600;
    }
  }
  .nav-bar {
    height: 60px;
    background: $dark-blue;
    .nav-items {
      cursor: pointer;
      margin: 0 45px;
    }
    .list {
      padding-right: 70px;
    }
    .create {
      padding-left: 70px;
    }
  }
  .nav-menu {
    min-height: 45px;
    background: $dark-blue;
  }
  .nav-items {
    color: $white !important;
    text-decoration: none;
  }
  .nav-items-m-font {
    font-size: 0.8rem !important;
  }
  .nav-item {
    .text-size-button {
      padding-right: 0;
      color: $white;
      font-size: 0.8rem;
    }
    .text-size-button-mobile {
      padding-right: 0;
      color: $white;
      font-size: 0.5rem;
    }
    .nav-items.activeMenu{
      font-weight: 700;
    }
    .nav-items.nav-items-m-font.activeMenu{
      font-weight: 700;
    }




    .activeMenu ~ .header-mark {
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 35px solid #004E8B;
      position: absolute;
      margin-left: 45px;
      font-weight: 600;
    }
  }
  ul.nav-scroll {
    white-space: nowrap;
    overflow-x: auto;
    padding: 0 2px;
    text-align: -webkit-center;
  }
  ul.nav-scroll li {
    display: inline-block;
    float: none;
    margin: 0px 10px;
  }
  /* width */
  ::-webkit-scrollbar {
    height: 8px !important;
    background: $dark-blue; //rgb(3, 95, 175)
    border-radius: 15px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(3, 95, 175);
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(3, 95, 175);
  }
}
.primaryNav{
  background-color: #f0f0f0;
  overflow: auto;
  white-space: nowrap;
}
.primaryNav::-webkit-scrollbar {
  display: none;
}
.primaryNav ul {
  padding-left:0px;
  background-color:#A8057B;
  margin-bottom: 0px;

  li{
    display:inline-block;

  }
  li a{
    padding: 0;
    margin: 0;
    min-height: 70px;
    background-color:#A8057B;
    padding:20px 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color:#A8057B;
      cursor:pointer;
      &:after {
        border-top:#004d8c;
      }
    }
    &.activeMenu {
      &:after {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        content: " ";
        position: absolute;
        bottom: 0px;
        left: 40%;
      }
    }
  }
}
















@media (max-width: 768px){
  .activeMenu ~ .header-mark {
    border-top: 50px solid #004E8B !important;
  }
}



// new Header

.header-block {
  position: relative;
  background-color: #FFFFFF;
  min-height: 100px;
}

.profile-div {
  display: flex;
  justify-content: flex-end;
}

.header-img, .logo-cav {
  position: absolute;
  top: 0;
  left: 0;
}
.header-img {
  width: 165px;
  height: 70px;
}

.logo-cav {
  width: 100px;
}
.menu-block {
  display: flex;
  align-items: center;
}
.profile-dropdown {
  position: relative;
  margin: 1.5rem 0.75rem;
}

.profile-dropdown .my-account-web a,.profile-dropdown .my-account-mobile a {
color: #101744;
font-weight: 600;
}

.dropdown-menu-dark{
  background-color:#FFFFFF !important;
  right: 0 !important;
}

.header-info-section {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2.5rem;
  max-width: 80%;
  width: 90%;
}

.header-info-section h1, .header-info-section p {
  margin-bottom: 0.75rem;
  letter-spacing: .1em;
  font-weight: 400;
  color: #000;
}

.header-info-section h1 {
  font-size: 32px;
  line-height: 39px;
}
.header-info-section p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .025em;
}



.primary-header {
  display: flex;
  height: 6rem;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 5px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.header-logo {
  display: flex;
  width: 100%;
  max-width: 320px;
  align-items: center;
  justify-content: space-between;
  --tw-border-opacity: 1;
  border-color: rgb(199 217 230 / var(--tw-border-opacity));
  padding: 0.5rem;
}


img, video {
  max-width: 100%;
  height: auto;
}

.header-logo span {
  display: none;
  cursor: pointer;
  margin-bottom: 10px;
}

.header-logo img {
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: 40px;
  margin-right: 30px;
  width: 160px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.secondary-header {
  position: relative;
  z-index: 10;
  display: flex;
  min-height: 96px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(199 217 230 / var(--tw-border-opacity));
}

.secondary-header .h1-text {
  padding-left: 1rem;
  padding-right: 1rem;
}
.primary-header h1 {
  display: flex;
  justify-content: center;
}
.h1-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(16 23 68 / var(--tw-text-opacity));
}

@media (min-width: 640px){
.account-blk {
    grid-template-columns: 135px 40px;
}
}
@media (max-width: 639px){
.account-blk {
    margin-right: 20px;
}
}
.account-blk {
    margin-right: 40px;
    display: grid;
    grid-template-columns: 155px 40px;
    justify-content: flex-end;
}

.account-blk div p:nth-child(1) {
  margin-right: 6px;
  margin-bottom: -2px;
  text-align: end;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(16 23 68 / var(--tw-text-opacity));
}
.account-blk p {
  text-align: end;
  font-family: Poppins, sans-serif;
}

.account-blk div p:nth-child(2) {
  margin-right: 6px;
  text-align: end;
  font-size: 14px;
  line-height: 20px;
  --tw-text-opacity: 1;
  color: rgb(16 23 68 / var(--tw-text-opacity));
}

.inactive-profile {
  height: 37px;
  width: 37px;
  border-radius: 9999px;
  background-color: transparent;
}
.p-0\.5 {
  padding: 0.125rem;
}

.secondary-header img {
  margin: 0px;
  margin-top: -1px;
  height: 35px;
  width: 35px;
  cursor: pointer;
}
@media (max-width: 992px){
.primary-header img {
  margin-top: 25px;
}
}
@media (max-width: 768px){
.primary-header img {
  margin-left: 20px;
}
}
@media (max-width: 639px){
.primary-header img {
  margin-right: 10px;
}
}
