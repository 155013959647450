@import '~bootstrap/scss/bootstrap.scss';
@import './variables.scss';

.btn {
  border-radius: 25px;
  font-weight: bold;
  min-height: 50px;
  padding: 0px 48px;
}

@media (max-width: 568px) {
  .btn {
    padding: 0px 30px;
  }
}

.btn:focus {
  box-shadow: none !important;
}

.btn:hover {
  box-shadow: 0px 8px 12px #00000029;
  color: #ffffff;
}

.btn-primary {
  @extend .btn;
  background: #2b388b;
  border-radius: 8px;
  border: 4px solid transparent;
  transition-duration: 150ms;
  transition-timing-function: linear;
}
.btn-primary:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  background-color: #2b388b;
  border: 4px solid #3471b7 !important;
}
.btn-secondary {
  @extend .btn;
  background: $secondary-bg !important;
  border: 1px solid #101744;
  color: #000;
}

.btn-green {
  @extend .btn;
  background: #101744 !important;
  color: #ffffff;
}

.btn-violet {
  @extend .btn;
  color: $white !important;
  background: #2b388b !important;
  border-radius: 8px;
}

.btn:disabled {
  opacity: 0.3;
}
.btn-secondary:disabled {
  color: #000 !important;
}
.btn-secondary:hover {
  color: #000 !important;
}
.react-datepicker {
  border: none !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  .react-datepicker__header {
    background: white !important;
    border: none !important;
  }
  .react-datepicker__day--selected {
    background-color: $green !important;
    border-radius: 50% !important;
    color: $black !important;
    font-weight: bold !important;
  }
  .react-datepicker__day--today {
    background-color: $input-bg !important;
    border: 1px solid #035faf !important;
    border-radius: 50% !important;
    color: #035faf !important;
  }
  .react-datepicker__day--today:focus-visible {
    border: 1px solid #035faf !important;
  }
}

.acces-btn {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 25px;
  font-weight: bold;
  min-height: 45px;
  padding: 0px 48px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-width: 200px;
}

.acces-btn.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.main_card {
  position: relative;
  margin: auto;
  width: 90%;
  max-width: 560px;
  border-radius: 33px;
  border: 1px solid #707070;
  background-color: rgb(255 255 255);
}

// Tabel
.data-table {
  thead {
    border: none;
    tr {
      th {
        border-bottom: 2px solid #ddd;
        background-color: #101744;
        color: #ffffff;
        letter-spacing: 0.8px;
        height: 52px;

        &:first-child {
          padding-left: 20px;
        }
        &:last-child {
          padding-right: 20px;
        }
        white-space: nowrap;
      }
    }
  }
  tbody {
    tr {
      &:nth-child(2n + 2) {
        td {
          background-color: #f7f7f7;
        }
      }
    }
    td {
      border: none;
      letter-spacing: 0.8px;
      background: $white;
      padding-top: 15px;
      padding-bottom: 15px;
      text-transform: capitalize;
      &:first-child {
        padding-left: 20px;
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
  }
  img {
    cursor: pointer;
  }
}

// End

.footer_nav_section a {
  height: max-content;
}
.menu-dropdown {
  font-weight: 700;
}
.menu-dropdown::after{
  content:'';
  position: absolute;
    right: 48px;
    bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    height: 0px;
    width: 0px;
    border-width: 10px;
    border-style: solid;
    border-top: 0px;
    border-left-color: rgb(255 255 255 );
   border-right-color: rgb(255 255 255 );
}
.sub-menu-dropdown {
  position: absolute;
  top: 79px;
  right: 14px;
  height: 30px;
  width: 180px;
  cursor: pointer;

  color: rgb(16 23 68);
}
.sub-menu-dropdown div,
.sub-menu-dropdown a {
  display: block;
  cursor: pointer;
  border: 1px solid rgb(199 217 230);
  background-color: rgb(255 255 255);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(16 23 68);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000);
  text-decoration: none;
}
.sub-menu-dropdown div:hover {

  background-color: rgb(222 239 249 );

  color: rgb(16 23 68 );
}
.bg_color{
  background-color: #101744 !important;
  color: #fff;
  padding: 14px 0.5rem !important;
}

.pop_head{
  --tw-bg-opacity: 1;
    background-color: rgb(168 5 123 / var(--tw-bg-opacity));
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.popup_border{
  background-color: #A8057B;
  min-height: 80px;
margin:auto;
display: flex;
align-items: center;
}
.popup_heading{
  color: #fff;
  font-size: 28px;
  text-align: left;
  font-weight: 700;
  margin: 0;
}

.content.client_credentials .row{
margin: 0;
}
.popup-card.lg {
  max-width: 1100px;
  width: 90%;
  border: 1px solid #B2B2B2;
    margin-top: 40px;
}