@import "styles/variables.scss";

button:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px !important;
}

select:focus-visible {
  outline: -webkit-focus-ring-color auto 2px !important;
}
img:focus-visible,
svg:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}

a:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}
.primaryNav a:focus-visible {
  outline-offset: -9px;
  outline: -webkit-focus-ring-color auto 2px;
}

h1[tabindex='0']:focus-visible,
h2[tabindex='0']:focus-visible,
h3[tabindex='0']:focus-visible,
h4[tabindex='0']:focus-visible,
h5[tabindex='0']:focus-visible,
h6[tabindex='0']:focus-visible,
p[tabindex='0']:focus-visible,
label[tabindex='0']:focus-visible,
span[tabindex='0']:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}

li[tabindex='0']:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}

input[type="text"]:focus-visible,
input[type="searcf"]:focus-visible,
input[type="password"]:focus-visible {
  outline-offset: 0px;
  outline: -webkit-focus-ring-color auto 0px;
}

input:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}

textarea:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}
input[type='checkbox'] + .checkmark:focus-visible,
input[type='radio'] + .checkmark:focus-visible,
input[type='checkbox']:focus-visible + .faq-toggle {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}
input[type='file']:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
  opacity: 1;
}

// input:focus-visible + .checkmark,
// input:focus-visible + .radio-checkmark,
// input[type='checkbox']:focus-visible + span,
// input[type='checkbox']:focus-visible + label,
// input[type='radio']:focus-visible + span,
// input[type='radio']:focus-visible + label {
//   outline-offset: 8px;
//   outline: -webkit-focus-ring-color auto 2px;
// }

*:focus {
  outline: none;
}
input[type='checkbox']:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
  outline-offset: 8px;
}
span[tabindex='0']:focus-visible,
div[tabindex='0']:focus-visible,
form[tabindex='0']:focus-visible,
video[tabindex='0']:focus-visible,
div[tabindex='1']:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
  outline-offset: 8px;
}


th[tabindex='0']:focus-visible,
td[tabindex='0']:focus-visible{
  outline: -webkit-focus-ring-color auto 2px;
  outline-offset: -4px;
}

button:focus-visible,
select:focus-visible {
  outline: #101010 auto 1px;
}

.table{
  margin-bottom: 0 !important;
}
.layout {
  background: $white;
  margin-bottom: 52px;
  .layout-container {
    max-width: 1220px;
    padding: 57px 40px;
    border: 1px solid #B2B2B2;
    margin-top: 40px;
  }
}

.primary-text {
  color: $primary-blue;
}

.secondary-text {
  color: $secondary-blue;
}

.app-sub-header {
  background: #fff;
  margin-bottom: 30px;
}

.multi-btn{
  display: grid;
  justify-content: flex-end;
  grid-template-columns: repeat(auto-fit,minmax(170px,max-content));
  gap: 20px;
}
@media (max-width:768px){
  .multi-btn{
    justify-content: center;
  }
  .multi-btn button{
    margin: 0 !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.mb-50 {
  margin-bottom: 50px;
}

.tex-align-l {
  text-align: left;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.blue-link {
  color: #035faf;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}

.mb-6 {
  margin-bottom: 6px;
}

.marginMobile {
  margin: 0 20px;
}
/* Video container */
video {
  max-width: 100%;
}

iframe {
  width: 100%;
  max-width: 659px;
}
.pdf-container img {
  max-width: 100%;
  width: 100%;
}

#pdfviewer img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

/* 2 Button container */

.double-cta-space {
  margin-right: 20px;
  display: inline-block !important;
}

/* END */

/* Markdown text editor message */
.tox-notification--warning {
  max-width: 285px !important;
  width: 100% !important;
  margin: auto !important;
}
/* END */

/* Edge browser */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
/* END */

/* Custom css time picker */

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -25px !important;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  left: -25px !important;
  top: -7px !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  left: -25px !important;
  top: 0px !important;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  left: -25px !important;
  top: -6px !important;
}

/* END */

/* Search field */

.search-box {
  background-image: url(../src/assets/images/search.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
}
/* END */

/* Media query */

@media (max-width: 768px) {
  .app-sub-header .search-container:first-child {
    margin-bottom: 20px;
    text-align: end;
    width: 100%;
  }
}

@media (max-width: 567px) {
  .double-cta-space {
    margin-right: 0px;
    margin-bottom: 20px !important;
    display: block !important;
    margin: auto;
  }
  .app-sub-header .search-container:first-child {
    margin-bottom: 20px;
    text-align: end;
    width: 100%;
  }
}

.custom-toast {
  height: auto !important;
  min-height: 127px;
}
/* END */

// /* Works on Firefox */
// * {
//   scrollbar-width: thin;
//   scrollbar-color: #b2c025 transparent;
// }

// /* Works on Chrome, Edge, and Safari */
// *::-webkit-scrollbar {
//   width: 5px;
//   background: rgb(240, 240, 240);
//   border-radius: 15px;
// }

// *::-webkit-scrollbar-track {
//   background: #FFF;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: #b2c025;
//   border-radius: 20px;
//   border: calc(20px / 4) solid #b2c025;
// }

.tox .tox-toolbar--scrolling {
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}

.view-container img{
  max-width: 100%;
}

.client_credentials{
  display: grid;
  gap: 25px;
}

.file_upload_btn{
  position: relative;
  display: inline-flex !important;
  align-items: center;
}