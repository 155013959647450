@import "../../styles/variables.scss";

.trust-management {
  .list-container {
    background: #fff;
    .horizontal-scrolll {
      overflow-x: auto;
      margin-top: 8px;
    }
    .scroll-content {
      // transform: rotateX(180deg);
    }
    /* width */
    ::-webkit-scrollbar {
      height: 8px !important;
      background: rgb(240, 240, 240);
      border-radius: 15px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background:  rgb(222, 224, 224);
      border-radius: 15px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background:  rgb(222, 224, 224);
    }
  }
  // .trust-list {
  //   thead {
  //     border: none;
  //     tr {
  //       th {
  //         border-bottom: 2px solid #ddd;
  //         background-color: $white;
  //         letter-spacing: 0.8px;
  //         padding-top: 30px;
  //         padding-bottom: 30px;
  //         &:first-child {
  //           padding-left: 20px;
  //         }
  //         &:last-child {
  //           padding-right: 20px;
  //         }
  //         white-space: nowrap;
  //       }
  //     }
  //   }
  //   tbody {
  //     tr {
  //       vertical-align: middle;
  //       &:nth-child(2n + 1) {
  //         td {
  //           background-color: #f7f7f7;
  //         }
  //       }
  //     }
  //     td {
  //       border: none;
  //       letter-spacing: 0.8px;
  //       background: $white;
  //       padding-top: 15px;
  //       padding-bottom: 15px;
  //       &:first-child {
  //         padding-left: 20px;
  //         font-size: 16px;
  //         font-weight: bold;
  //         color: $secondary-blue;
  //       }
  //       &:last-child {
  //         padding-right: 20px;
  //       }
  //     }
  //   }
  //   img {
  //     cursor: pointer;
  //   }
  // }
  .search-container {
    display: flex;
    position: relative;
    .search-transition {
      transition: all 0.5s ease-in;
    }

    .search-input {
      width: 100%;
      height: 50px;
      border: 1px solid $green;
      outline: none;
      padding-left: 15px;
      padding-right: 50px;
    }
    .search-btn {
      position: absolute;
      right: 10%;
    }
  }
}

.user-form {
  max-width: 475px;
  width:90%;
}

.error {
  color: red;
  text-align: center;
}

.role-permission {
  .filter-checkbox {
    width: 24px;
    height: 24px;
    background: #e8f0fe 0% 0% no-repeat padding-box;
    box-shadow: inset 1px 1px 1px #00000029;
    border: 1px solid #004e8b;
    border-radius: 0 !important;
    opacity: 1;
    margin-top: 0;
    cursor: pointer;
    flex-shrink: 0;
  }
  .filter-checkbox:checked[type="checkbox"] {
    background-color: $input-bg !important;
    border: 1px solid $dark-blue !important;
    background-position: center;
    background-image: url(../../assets/images/check.svg);
  }
  .filter-label {
    font-size: 14px;
    letter-spacing: 0.7px;
    color: $black;
  }
}
.copy-icon {
  height: 28px;
  width: 28px;
  float: right;
  cursor: pointer;
}
.show-border {
  border: 4px solid rgb(3, 95, 175);
}
.hide-border {
  border: none;
}
.copy-div {
  display: flex;
  align-items: center;
}
.copy-label {
  font-size: 12pt;
  float: left;
  margin-bottom: 5px;
}
.detail-input {
  background-color: rgb(232, 240, 254) !important;
}
.form-group-cred {
  margin-bottom: 27px;
}
.copied-flag {
  opacity: unset;
  pointer-events: none;
  background-color: #5a96cb !important;
  border-color: #5a96cb !important;
  color: #fff !important;
}

input + .form-check-label,.form-check-label {
  cursor: pointer;
}
