@import "styles/font.scss";
@import "styles/variables.scss";

body {
  margin: 0;
  font-family: Montserrat !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#fff;
}

code {
  font-family: Montserrat;
}
