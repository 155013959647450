@import "../../styles/variables.scss";

$primarily: #9b9b9b;
$light-gray: lighten($primarily, 35);
$gray: rgba($primarily, 0.3);

.pagination {
  display: flex;
  align-items: center;

  margin-top: 40px;
  padding: 0;

  font-size: 18px;
  font-weight: 700;
  list-style: none;
  user-select: none;
  color: #003A69;
  letter-spacing: 0.9px;

  .page-item {
    min-width: 37px;
    min-height: 32px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 2px;

    transition: all 0.3s;
    background-color: #FFD460;
    // padding: 0;
    &:hover {
      background: #F07B3F;
      cursor: pointer;
    }

    &.active {
    //   padding: 0;
      background: #F07B3F;
    }

    &.arrow-icon {
      position: relative;
      z-index: 1;

      font-size: 35px;

      transform: translateY(-2px);
      transition: all 0.3s;
      background-color: transparent;
      &:after {
        content: "";
        width: 25px;
        height: 25px;

        position: absolute;
        z-index: -1;
        top: 56%;
        left: 50%;

        transform: translate(-50%, -50%);
      }

      &:hover {
        background: transparent;
        &:after {
          background: $light-gray;
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: $gray;
        &:hover {
          background: transparent;
        }
      }
    }
  }
}
